<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { Button, Checkbox, Dialog } from '@xpanseinc/ui-components';
  import uniqueId from 'lodash-es/uniqueId';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import FormRow from '../FormRow.svelte';
  import FormBlock from '../FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import {
    PartyContactTypeEnum,
    PartyPartyRoleEnum,
    TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import { getComponent, getProps } from '../renderFromConfig';
  import { getBorrowerSchema } from '../../../schemas/place-order/index';
  import { addContactConfig, OrgTypeEnum } from '../../../constants/place-order';
  import { imask, masks } from '../../../constants/inputMasking';
  import { emailIsValid, phoneIsValid, ssnIsValid } from '../../../utils/validation';
  import { mapDropdownOptionsFromEnum } from '../../../utils/mapDropdownOptions';
  import { usStates, fetchStates } from '../../../stores/usStates';
  import { geoApi } from '../../../stores/api';
  import { form } from '../../../stores/placeOrder';
  import type { ValidationResult } from '../../../schemas/validate';
  import type { Address, Borrower, ContactMethod } from '../../../schemas/place-order/index';

  export let visible = false;
  export let existingItem = null;
  export let orgType = undefined;
  export let showErrorMsg = false;

  const { top, companyInformation, contactDetails, contactInformation, taxInformation, address } =
    addContactConfig(orgType);

  const dispatch = createEventDispatcher();
  let dirtyMap: { [k in keyof Borrower]?: boolean } = {};
  let dirtyMapAddress: { [k in keyof Address]?: boolean } = {};
  let hasOpened = false;

  let stagedBorrower: Borrower = getDefaultForm();

  fetchStates();

  function getDefaultForm(): Borrower {
    return {
      partyRole: PartyPartyRoleEnum.Borrower,
      contactType: PartyContactTypeEnum.Individual,
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: null,
      taxIdType: null,
      taxId: null,
      maritalStatus: null,
      bankruptcyLast7: false,
      foreclosureLast7: false,
      partyToLawsuit: false,
      contactMethods: [getNewContactMethod()],
      address: {
        line1: '',
        line2: '',
        state: '',
        zipCode: '',
        county: '',
        city: '',
      },
      sameAsSubjectProperty: false,
    };
  }

  async function onZipcodeChange(e) {
    const { value } = e.target;

    stagedBorrower.address.zipCode = value;
    if (!value) {
      return;
    }

    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });

      stagedBorrower.address.city = city;
      stagedBorrower.address.county = county;
      stagedBorrower.address.state = state;
    } catch (error) {
      console.error(error);
    }
  }

  function getNewContactMethod(): ContactMethod {
    return {
      id: uniqueId(),
      contactName: '',
      method: null,
      details: '',
      preferred: false,
    };
  }

  function removeContactMethod(i) {
    const [removedContactMethod] = stagedBorrower.contactMethods.splice(i, 1);
    stagedBorrower = stagedBorrower;

    // remove items from the dirtyMap for this contact method
    Object.keys(dirtyMap)
      .filter((k: string) => k.indexOf(`contactMethods[${removedContactMethod.id}]`) > -1)
      .forEach((k: string) => {
        delete dirtyMap[k];
      });

    if (stagedBorrower.contactMethods.length === 0) {
      stagedBorrower.contactMethods = [getNewContactMethod()];
    }
  }

  function deleteBorrower() {
    dispatch('delete', stagedBorrower);
    close();
  }

  function saveBorrower() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors)
        .filter((key) => key.indexOf('contactMethod') === -1)
        .forEach((key) => {
          dirtyMap[key] = true;
        });
      stagedBorrower.contactMethods.forEach((contactMethod) => {
        const key = `contactMethods[${contactMethod.id}]`;
        Object.keys(contactMethod).forEach((field) => {
          dirtyMap[`${key}.${field}`] = true;
        });
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedBorrower });
    } else {
      dispatch('save', stagedBorrower);
    }
    hasOpened = false;
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
    hasOpened = false;
  }

  function validateTaxId(id) {
    let valid = true;
    if (
      stagedBorrower.taxIdType === TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber
    ) {
      valid = ssnIsValid(id);
    }
    return valid;
  }

  function validateMethods(contacts) {
    const validMap = contacts.map((contact) => {
      const { method, details } = contact;
      let valid;
      if (method === 'Email') {
        valid = emailIsValid(details);
      } else if (method === 'Phone' || method === 'Fax') {
        valid = phoneIsValid(details);
      } else {
        valid = method !== null && details.length > 0;
      }
      return valid;
    });
    return validMap;
  }

  function getTaxIdMask(taxIdType) {
    switch (taxIdType) {
      case TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber:
        return masks.ssn;
      case TaxpayerIdentifierTaxpayerIdentifierTypeEnum.EmployerIdentificationNumber:
        return masks.ein;
      default:
        return null;
    }
  }
  $: taxIdMask = getTaxIdMask(stagedBorrower.taxIdType);

  $: if (!visible) {
    dirtyMap = {};
    stagedBorrower = getDefaultForm();
  }

  $: if (existingItem && !hasOpened) {
    stagedBorrower = { ...existingItem };
    hasOpened = true;
  }

  $: if (stagedBorrower.sameAsSubjectProperty && $form.loanDetails.newLoan.subjectProperty.length) {
    const { addressLine1, addressLine2, city, county, state, zipCode } =
      $form.loanDetails.newLoan.subjectProperty[0];

    stagedBorrower.address = {
      line1: addressLine1,
      line2: addressLine2,
      zipCode,
      city,
      county,
      state,
    };
  }

  $: borrowerSchema = getBorrowerSchema(stagedBorrower.contactType);

  let validationResult: ValidationResult;
  $: validationResult = validate(borrowerSchema, stagedBorrower);

  let contactMethodsValidationResult;
  $: contactMethodsValidationResult = validateMethods(stagedBorrower.contactMethods);
  $: {
    if (showErrorMsg) {
      for (const k in borrowerSchema.fields) {
        dirtyMap[k] = true;
      }
      for (const { id } of stagedBorrower.contactMethods) {
        dirtyMap[`contactMethods[${id}].contactName`] = true;
        dirtyMap[`contactMethods[${id}].method`] = true;
        dirtyMap[`contactMethods[${id}].details`] = true;
      }
    }
  }
</script>

<Dialog
  title="Add Borrower"
  titleTag="Required Fields *"
  on:close="{close}"
  size="jumbo"
  bind:visible
>
  <div slot="body" class="body">
    <FormRow>
      <FormBlock width="100%">
        <div class="row">
          <div style="width: 184px;">
            <svelte:component
              this="{getComponent(top.fields.contactType)}"
              {...getProps(top.fields.contactType)}
              options="{mapDropdownOptionsFromEnum(PartyContactTypeEnum, true, 'key').filter(
                (x) => x.value === PartyContactTypeEnum.Individual,
              )}"
              invalid="{dirtyMap.contactType && validationResult.errors.contactType}"
              value="{stagedBorrower.contactType}"
              locked
              on:blur="{() => {
                dirtyMap.contactType = true;
              }}"
              on:select="{(e) => {
                stagedBorrower.contactType = e.detail.value;
              }}"
            />
          </div>
          <div style="width: 240px;">
            <svelte:component
              this="{getComponent(top.fields.contactRole)}"
              {...getProps(top.fields.contactRole)}
              options="{mapDropdownOptionsFromEnum(PartyPartyRoleEnum, true, 'key').filter(
                (x) =>
                  x.value === PartyPartyRoleEnum.Borrower ||
                  x.value === PartyPartyRoleEnum.Coborrower,
              )}"
              invalid="{dirtyMap.partyRole && validationResult.errors.partyRole}"
              value="{stagedBorrower.partyRole}"
              on:blur="{() => {
                dirtyMap.partyRole = true;
              }}"
              on:select="{(e) => {
                stagedBorrower.partyRole = e.detail.value;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="Borrower Infomation">
        <div class="row">
          {#if !stagedBorrower.contactType || stagedBorrower.contactType === PartyContactTypeEnum.Individual}
            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.firstName)}"
                {...getProps(contactInformation.fields.firstName)}
                value="{stagedBorrower.firstName}"
                invalid="{dirtyMap.firstName && validationResult.errors.firstName}"
                on:blur="{() => {
                  dirtyMap.firstName = true;
                }}"
                on:change="{(e) => {
                  stagedBorrower.firstName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 160px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.middleName)}"
                {...getProps(contactInformation.fields.middleName)}
                value="{stagedBorrower.middleName}"
                on:change="{(e) => {
                  stagedBorrower.middleName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.lastName)}"
                {...getProps(contactInformation.fields.lastName)}
                value="{stagedBorrower.lastName}"
                invalid="{dirtyMap.lastName && validationResult.errors.lastName}"
                on:blur="{() => {
                  dirtyMap.lastName = true;
                }}"
                on:change="{(e) => {
                  stagedBorrower.lastName = e.target.value;
                }}"
              />
            </div>
          {:else}
            <div style="width: 320px;">
              <svelte:component
                this="{getComponent(companyInformation.fields.companyName)}"
                {...getProps(companyInformation.fields.companyName)}
                value="{stagedBorrower.companyName}"
                invalid="{dirtyMap.companyName && validationResult.errors.companyName}"
                on:blur="{() => {
                  dirtyMap.companyName = true;
                }}"
                on:change="{(e) => {
                  stagedBorrower.companyName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(companyInformation.fields.relationshipVestingType)}"
                {...getProps(companyInformation.fields.relationshipVestingType)}
                value="{stagedBorrower.relationshipVestingType}"
                invalid="{dirtyMap.relationshipVestingType &&
                  validationResult.errors.relationshipVestingType}"
                on:blur="{() => {
                  dirtyMap.relationshipVestingType = true;
                }}"
                on:select="{(e) => {
                  stagedBorrower.relationshipVestingType = e.detail.value;
                }}"
              />
            </div>
          {/if}
        </div>
        <div class="row">
          <div style="width: 280px;">
            <svelte:component
              this="{getComponent(taxInformation.fields.taxIdType)}"
              {...getProps(taxInformation.fields.taxIdType)}
              value="{stagedBorrower.taxIdType}"
              invalid="{dirtyMap.taxIdType && validationResult.errors.taxIdType}"
              on:blur="{() => {
                dirtyMap.taxIdType = true;
              }}"
              on:select="{(e) => {
                stagedBorrower.taxIdType = e.detail.value;
              }}"
            />
          </div>
          <div style="width: 200px;">
            <svelte:component
              this="{getComponent(taxInformation.fields.taxId)}"
              {...getProps(taxInformation.fields.taxId)}
              invalid="{!validateTaxId(stagedBorrower.taxId) && dirtyMap.taxId}"
              maskPackage="{imask}"
              maskOptions="{taxIdMask}"
              on:blur="{() => {
                dirtyMap.taxId = true;
              }}"
              on:change="{(e) => {
                if (!taxIdMask) {
                  stagedBorrower.taxId = e.target.value;
                }
              }}"
              on:accept="{({ detail: { masked } }) => {
                stagedBorrower.taxId = masked.unmaskedValue;
              }}"
            />
          </div>
        </div>
        {#if stagedBorrower.contactType === PartyContactTypeEnum.Individual}
          <div class="row">
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(taxInformation.fields.maritalStatus)}"
                {...getProps(taxInformation.fields.maritalStatus)}
                value="{stagedBorrower.maritalStatus}"
                on:select="{(e) => {
                  stagedBorrower.maritalStatus = e.detail.value;
                }}"
              />
            </div>
          </div>
        {/if}
      </FormBlock>
      <FormBlock>
        {#if !(orgType === OrgTypeEnum.BROKER) && !(orgType === OrgTypeEnum.WHOLESALE)}
          <div class="checkboxes">
            <div class="checkbox">
              <svelte:component
                this="{getComponent(taxInformation.fields.bankruptcyInLast7Years)}"
                {...getProps(taxInformation.fields.bankruptcyInLast7Years)}
                on:change="{() => {
                  stagedBorrower.bankruptcyLast7 = !stagedBorrower.bankruptcyLast7;
                }}"
              >
                {taxInformation.fields.bankruptcyInLast7Years.label}
              </svelte:component>
            </div>
            <div class="checkbox">
              <svelte:component
                this="{getComponent(taxInformation.fields.foreclosureInTheLast7years)}"
                {...getProps(taxInformation.fields.foreclosureInTheLast7years)}
                on:change="{() => {
                  stagedBorrower.foreclosureLast7 = !stagedBorrower.foreclosureLast7;
                }}"
              >
                {taxInformation.fields.foreclosureInTheLast7years.label}
              </svelte:component>
            </div>
            <div class="checkbox">
              <svelte:component
                this="{getComponent(taxInformation.fields.partyToLawsuit)}"
                {...getProps(taxInformation.fields.partyToLawsuit)}
                on:change="{() => {
                  stagedBorrower.partyToLawsuit = !stagedBorrower.partyToLawsuit;
                }}"
              >
                {taxInformation.fields.partyToLawsuit.label}
              </svelte:component>
            </div>
          </div>
        {/if}
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="{address.title}" width="632px">
        {#if $form.loanDetails.newLoan.subjectProperty.length}
          <Checkbox
            name="tx50a6"
            bind:checked="{stagedBorrower.sameAsSubjectProperty}"
            on:change="{() => {
              if (!stagedBorrower.sameAsSubjectProperty) {
                stagedBorrower.address = {
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  zipCode: '',
                  county: '',
                };
              }
            }}"
          >
            Same as subject property
          </Checkbox>
        {/if}

        {#if !stagedBorrower.sameAsSubjectProperty}
          <div class="row">
            <svelte:component
              this="{getComponent(address.fields.addressLine1)}"
              {...getProps(address.fields.addressLine1)}
              value="{stagedBorrower.address.line1}"
              invalid="{dirtyMapAddress.line1 && validationResult.errors.line1}"
              on:blur="{() => {
                dirtyMapAddress.line1 = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.address.line1 = e.target.value;
              }}"
            />
          </div>
          <div class="row">
            <svelte:component
              this="{getComponent(address.fields.addressLine2)}"
              {...getProps(address.fields.addressLine2)}
              value="{stagedBorrower.address.line2}"
              invalid="{dirtyMapAddress.line2 && validationResult.errors.line2}"
              on:blur="{() => {
                dirtyMapAddress.line2 = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.address.line2 = e.target.value;
              }}"
            />
          </div>
          <div class="row">
            <svelte:component
              this="{getComponent(address.fields.zipCode)}"
              {...getProps(address.fields.zipCode)}
              value="{stagedBorrower.address.zipCode}"
              invalid="{dirtyMapAddress.zipCode && validationResult.errors.zipCode}"
              on:blur="{() => {
                dirtyMapAddress.zipCode = true;
              }}"
              on:change="{(e) => {
                onZipcodeChange(e);
              }}"
            />
            <svelte:component
              this="{getComponent(address.fields.city)}"
              {...getProps(address.fields.city)}
              value="{stagedBorrower.address.city}"
              invalid="{dirtyMapAddress.city && validationResult.errors.city}"
              on:blur="{() => {
                dirtyMapAddress.city = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.address.city = e.target.value;
              }}"
            />
            <svelte:component
              this="{getComponent(address.fields.state)}"
              {...getProps(address.fields.state)}
              options="{$usStates}"
              value="{stagedBorrower.address.state}"
              invalid="{dirtyMapAddress.state && validationResult.errors.state}"
              showTextFilter
              on:blur="{() => {
                dirtyMapAddress.state = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.address.state = e.target.value;
              }}"
            />
            <svelte:component
              this="{getComponent(address.fields.county)}"
              {...getProps(address.fields.county)}
              value="{stagedBorrower.address.county}"
              invalid="{dirtyMapAddress.county && validationResult.errors.county}"
              on:blur="{() => {
                dirtyMapAddress.county = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.address.county = e.target.value;
              }}"
            />
          </div>
        {/if}
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="Contact Methods">
        {#each stagedBorrower.contactMethods as { id, contactName, details, method, preferred }, index (id)}
          <div class="row">
            {#if stagedBorrower.contactType && stagedBorrower.contactType !== PartyContactTypeEnum.Individual}
              <div style="width: 216px;">
                <svelte:component
                  this="{getComponent(contactDetails.fields.contactName)}"
                  {...getProps(contactDetails.fields.contactName)}
                  value="{stagedBorrower.contactMethods[index].contactName}"
                  invalid="{dirtyMap[`contactMethods[${id}].contactName`] &&
                    validationResult.errors[`contactMethods[${index}].contactName`]}"
                  on:blur="{() => {
                    dirtyMap[`contactMethods[${id}].contactName`] = true;
                  }}"
                  on:change="{(e) => {
                    stagedBorrower.contactMethods[index].contactName = e.target.value;
                  }}"
                />
              </div>
            {/if}
            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactDetails.fields.method)}"
                {...getProps(contactDetails.fields.method)}
                value="{stagedBorrower.contactMethods[index].method}"
                invalid="{dirtyMap[`contactMethods[${id}].method`] &&
                  validationResult.errors[`contactMethods[${index}].method`]}"
                on:blur="{() => {
                  dirtyMap[`contactMethods[${id}].method`] = true;
                }}"
                on:select="{(e) => {
                  stagedBorrower.contactMethods[index].method = e.detail.value;
                }}"
              />
            </div>
            <div style="width: 328px;">
              <svelte:component
                this="{getComponent(contactDetails.fields.details)}"
                {...getProps(contactDetails.fields.details)}
                value="{stagedBorrower.contactMethods[index].details}"
                invalid="{(dirtyMap[`contactMethods[${id}].details`] &&
                  validationResult.errors[`contactMethods[${index}].details`]) ||
                  (dirtyMap[`contactMethods[${id}].details`] &&
                    !contactMethodsValidationResult[index])}"
                on:blur="{() => {
                  dirtyMap[`contactMethods[${id}].details`] = true;
                }}"
                on:change="{(e) => {
                  stagedBorrower.contactMethods[index].details = e.target.value;
                }}"
              />
            </div>
            <svelte:component
              this="{getComponent(contactDetails.fields.preferred)}"
              {...getProps(contactDetails.fields.preferred)}
              value="{stagedBorrower.contactMethods[index].preferred}"
              bind:checked="{stagedBorrower.contactMethods[index].preferred}"
            >
              {contactDetails.fields.preferred.label}
            </svelte:component>
            <div on:click="{() => removeContactMethod(index)}">
              <Trash2Icon size="24" />
            </div>
          </div>
        {/each}
      </FormBlock>
    </FormRow>

    <Button
      color="basic"
      label="Add Contact Method"
      name="addContactMethod"
      icon="{PlusIcon}"
      on:click="{() => {
        stagedBorrower.contactMethods = [...stagedBorrower.contactMethods, getNewContactMethod()];
      }}"
    />
  </div>
  <footer slot="footer" class="footer">
    <div class="{existingItem ? 'button-container' : ''}">
      {#if existingItem}
        <Button name="close-recording-dialog" label="Delete" on:click="{deleteBorrower}" />
      {/if}
      <div>
        <Button name="close-recording-dialog" label="Close" on:click="{close}" />
        <Button
          color="primary"
          name="close-recording-dialog"
          label="Save"
          disabled="{!validationResult.valid || contactMethodsValidationResult.includes(false)}"
          on:click="{saveBorrower}"
        />
      </div>
    </div>
  </footer>
</Dialog>

<style>
  .body,
  .footer {
    margin: 0 50px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    padding-top: 34px;
  }

  .checkbox {
    margin-bottom: 20px;
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }

  :global(.text-input) {
    width: 100%;
  }
</style>
