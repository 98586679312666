<script>
  import {
    BriefcaseIcon,
    CalendarIcon,
    CheckCircleIcon,
    ClipboardIcon,
    DownloadCloudIcon,
    MailIcon,
    UserIcon,
    GlobeIcon,
    DollarSignIcon,
  } from 'svelte-feather-icons';
  import { emailIsValid } from '../utils/validation';
  import { startCase, camelCase } from 'lodash-es';
  import prettyBytes from 'pretty-bytes';
  import LogDetail from './LogDetail.svelte';
  import LogStatus from './LogStatus.svelte';
  import { attachmentApi } from '../stores/api';
  import { orderLogIcons } from '../constants/orderLogIcons';
  import { getLabel } from '../constants/sendEventDataLabels';
  import { downloadFile } from '../utils/download';
  import simpleFormatDate from '../utils/simpleFormatDate';
  import { convertUtcOffset, formatDate, getTzFromUtcOffset } from '../utils/formatDate';
  import { formatFHA } from '../utils/formatFHA';
  import { formatISO } from 'date-fns';
  import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz';

  export let log;
  export let product;

  let borrowers = 0;

  const downloading = {};
  const buildDownloading = () =>
    log.attachments?.forEach((attachment) => {
      downloading[attachment.id] = false;
    });
  buildDownloading();

  async function onDownloadClick(attachment) {
    downloading[attachment.id] = true;
    const response = await $attachmentApi
      .downloadAttachemntRaw({
        attachmentId: attachment.id,
      })
      .catch((err) => console.error(err))
      .finally(() => {
        downloading[attachment.fileName] = false;
      });
    await downloadFile(response.raw.blob(), attachment.fileName);
    downloading[attachment.id] = false;
  }

  const fileExtension = (file) => {
    const fileName = file.split('.');
    return fileName[fileName.length - 1];
  };

  const formatAttachmentUsageCode = (attachment) => {
    if (attachment.usageCode === 'Other') {
      return `${attachment.usageCode} (${attachment.usageCodeDescription})`;
    }
    return attachment.usageCodeDescription || 'Download File';
  };

  function getFormattedFileSize(bytes) {
    if (bytes > 0) {
      return prettyBytes(bytes);
    }
    return '';
  }

  const getLogIcon = () => {
    if (log.status && orderLogIcons[log.eventId]) {
      return orderLogIcons[log.eventId][log.status] || GlobeIcon;
    }
    if (log.eventId === 'UpdateStatus') {
      return CheckCircleIcon;
    }
    return orderLogIcons[log.eventId] || GlobeIcon;
  };

  function formatBorrower({ partyRole }) {
    if (partyRole === 'BORROWER') borrowers++;
    const prepend = partyRole === 'BORROWER' && borrowers > 1 ? 'Co-' : '';
    return prepend + startCase(camelCase(partyRole));
  }

  function getClosingDate() {
    const date = log?.closingData?.closingDate;
    const utcOffset = log?.closingData?.displayTimeZoneText;
    const tzLabel = getTzFromUtcOffset(date, utcOffset);

    if (utcOffset && tzLabel) {
      return formatDate(date, tzLabel, null, convertUtcOffset(utcOffset));
    } else {
      return formatDate(log.closingData.closingDate, 'ET');
    }
  }
</script>

<div class="single-log" data-testid="{`${log.title}-${log.id}`}">
  <div class="header">
    <div
      class="icon-container"
      class:lender-icon="{log.eventFrom === 'LENDER'}"
      class:vendor-icon="{log.eventFrom === 'VENDOR'}"
    >
      <div class="icon">
        <svelte:component this="{getLogIcon()}" />
      </div>
    </div>
    <div class="header-content">
      <div>
        <div
          class="event-name"
          class:lender-event="{log.eventFrom === 'LENDER'}"
          class:vendor-event="{log.eventFrom === 'VENDOR'}"
        >
          <h4 class="header-m header-m--bold">
            {log?.title}
          </h4>
        </div>
        <div class="event-date">
          {formatDate(log.createdAt, 'ET')}
        </div>
      </div>
      <div class="entered-by">
        By
        {#if emailIsValid(log?.createdBy)}
          <a class="link" href="mailto:{log?.createdBy}" title="Open in email client"
            >{log?.createdBy}
          </a>
        {:else}
          {log?.createdBy}
        {/if}
      </div>
    </div>
  </div>
  <div class="log-body">
    <div class="log-detail-container">
      {#if log?.attachments}
        {#each log.attachments as attachment}
          <button
            class="log-detail btn-unstyled"
            on:click|preventDefault="{() => onDownloadClick(attachment)}"
          >
            <LogDetail
              theme="blue"
              icon="{DownloadCloudIcon}"
              bind:downloading="{downloading[attachment.id]}"
            >
              <div class="ellipsis" slot="label" title="{formatAttachmentUsageCode(attachment)}">
                {formatAttachmentUsageCode(attachment)}
              </div>
              <div class="download-info" slot="info">
                <p>
                  {fileExtension(attachment.fileName)}
                </p>
                {#if attachment.size && attachment.size > 0}
                  <p>
                    {getFormattedFileSize(attachment.size)}
                  </p>
                {/if}
              </div>
            </LogDetail>
          </button>
        {/each}
      {/if}

      {#if log?.closingData?.closingDate.valueOf() > 0}
        <div class="log-detail">
          <LogDetail
            details="{log.closingData}"
            icon="{CalendarIcon}"
            theme="gold"
            title="{log?.title}"
          >
            <div slot="label">
              Document signing on
              <div class="signing-info">
                <p>{getClosingDate()}</p>
              </div>
            </div>
          </LogDetail>
        </div>
      {/if}

      {#if log?.inspectionSchedule?.valueOf() > 0}
        <div class="log-detail">
          <LogDetail
            details="{log.inspectionSchedule}"
            icon="{CalendarIcon}"
            theme="gold"
            title="{log?.title}"
          >
            <div slot="label">
              Inspection scheduled on {simpleFormatDate(log.inspectionSchedule)}
            </div>
          </LogDetail>
        </div>
      {/if}

      {#if log?.inspectedDate?.valueOf() > 0}
        <div class="log-detail">
          <LogDetail
            details="{log.inspectedDate}"
            icon="{CalendarIcon}"
            theme="gold"
            title="{log?.title}"
          >
            <div slot="label">
              Inspection completed on {formatDate(log.inspectedDate)}
            </div>
          </LogDetail>
        </div>
      {/if}

      {#if log?.parties}
        {#each log.parties as party}
          <div class="log-detail">
            <LogDetail
              details="{party}"
              icon="{party?.fullName ? BriefcaseIcon : UserIcon}"
              theme="green"
              title="{log?.title}"
            >
              <div slot="label">
                {#if party?.fullName}
                  {party.fullName}
                  {party?.firstName || party?.lastName
                    ? `(${party.firstName || ''} ${party.lastName || ''})`
                    : ''}
                {:else}
                  <div class="detail-label">
                    <div>
                      {party?.firstName || ''}
                      {party?.lastName || ''}
                    </div>
                  </div>
                  <div class="detail-subtitle">
                    <p>
                      {party?.partyRole ? `${formatBorrower(party)}` : ''}
                    </p>
                  </div>
                {/if}
              </div>
            </LogDetail>
          </div>
        {/each}
      {/if}

      {#if log?.recordingEndorsement}
        <div class="log-detail">
          <LogDetail
            details="{log.recordingEndorsement}"
            icon="{ClipboardIcon}"
            theme="orange"
            title="{log?.title}"
          >
            <div slot="label">
              {log.recordingEndorsement?.recordedDatetime
                ? `Recorded on ${formatDate(log.recordingEndorsement.recordedDatetime)}`
                : 'Date Unknown'}
            </div>
          </LogDetail>
        </div>
      {/if}
      {#if log?.fhaCaseIdentifier}
        <div class="log-detail">
          <h5 class="label">{getLabel('fhaCaseIdentifier')}:</h5>
          <p class="body">
            {formatFHA(log.fhaCaseIdentifier)}
          </p>
        </div>
      {/if}

      {#if log?.trackingInfo?.deliveryInitiateDate?.valueOf() > 0}
        <div class="log-detail">
          <LogDetail
            details="{log.trackingInfo}"
            icon="{MailIcon}"
            title="{log?.title}"
            theme="orange"
          >
            <div slot="label">
              {`Delivery Initiated on ${formatDate(log.trackingInfo.deliveryInitiateDate, 'ET')}`}
            </div>
          </LogDetail>
        </div>
      {/if}
    </div>
    {#if log?.termsOfLoan?.loanPurposeType}
      <div class="comment">
        <h5 class="label">{getLabel('loanPurposeType')}:</h5>
        <p class="body">
          {startCase(camelCase(log.termsOfLoan.loanPurposeType))}
          {log.termsOfLoan?.loanPurposeTypeOtherDescription
            ? ` - ${log.termsOfLoan?.loanPurposeTypeOtherDescription}`
            : ''}
        </p>
      </div>
    {/if}

    {#if log?.reasons}
      <div class="reason">
        {#each log.reasons as reason}
          <h5 class="label">Reason:</h5>
          <p class="body">
            {`${reason.reasonCode}${
              reason.reasonCodeDescription ? ` - ${reason.reasonCodeDescription}` : ''
            }`}
          </p>
          {#if reason.comment}
            <h5 class="label">{getLabel('comment')}:</h5>
            <p class="body">{reason.comment}</p>
          {/if}
        {/each}
      </div>
    {/if}
    {#if log?.additionalData?.paymentOrderRequest?.feeModificationAmount || log?.feeModificationAmount}
      <div class="log-detail-container">
        <div class="log-detail">
          <LogDetail icon="{DollarSignIcon}" theme="blue">
            <div slot="label">
              <div class="detail-label">
                {log?.additionalData?.paymentOrderRequest?.feeModificationAmount
                  .toFixed(2)
                  .toLocaleString() || log.feeModificationAmount.toFixed(2).toLocaleString()}
              </div>
            </div>
          </LogDetail>
        </div>
      </div>
    {/if}
    {#if log?.feeModificationPayments?.length}
      <h5 class="label">Payments:</h5>
      <div class="log-detail-container">
        {#each log.feeModificationPayments as payment}
          <div class="log-detail">
            <LogDetail icon="{DollarSignIcon}" theme="blue">
              <div slot="label">
                <div class="detail-label">
                  {payment.amount.toFixed(2).toLocaleString()} payment
                </div>
                <div class="detail-subtitle">
                  <p>{startCase(camelCase(payment.billToPartyRoleType))}</p>
                </div>
              </div>
            </LogDetail>
          </div>
        {/each}
      </div>
    {/if}
    {#if log?.comments}
      {#each log.comments as comment}
        {#if comment}
          <div class="comment">
            <h5 class="label">{getLabel('comment')}:</h5>
            <p class="body">{comment}</p>
          </div>
        {/if}
      {/each}
    {/if}
    <div class="status">
      <LogStatus
        eventId="{log.id}"
        deliveryStatus="{log.deliveryStatus}"
        deliveryRetryCount="{log.deliveryRetryCount}"
        product="{product}"
        timeToDeliver="{log.timeToDeliver}"
      />
    </div>
  </div>
</div>

<style>
  .single-log {
    margin-bottom: 1.5em;
  }

  .header {
    display: flex;
  }

  .icon-container {
    align-items: center;
    border-radius: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
    flex: 0 0 40px;
  }

  .icon {
    height: 22px;
    width: 22px;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 48px;
    padding-left: 8px;
    width: 100%;
  }

  .header-content :first-child {
    display: flex;
    flex: 1;
  }

  .download-info {
    display: inline-flex;
    flex-basis: 100%;
    width: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-left: 28px;
    color: var(--textDefault);
  }

  .download-info p {
    margin-bottom: 0;
  }

  .download-info p:first-child {
    margin-right: 0.5rem;
  }

  .signing-info {
    display: flex;
    flex-basis: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-left: 0;
    padding-right: 4px;
    color: var(--textDefault);
  }

  .signing-info p {
    margin-bottom: 0;
  }

  .detail-label {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    color: var(--textDark);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }

  .detail-subtitle {
    display: inline-flex;
    flex-basis: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--textDefault);
  }

  .detail-subtitle p {
    margin-bottom: 0;
  }

  .detail-subtitle p:first-child {
    margin-right: 0.5rem;
  }

  .event-name .header-m {
    color: var(--textDark);
  }

  .event-date {
    color: var(--textDefault);
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    justify-content: flex-end;
  }

  .entered-by {
    color: var(--textDefault);
    flex: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .entered-by .link {
    display: inline;
  }

  .log-body {
    display: flex;
    flex-direction: column;
    margin: 8px 0 16px 48px;
  }

  .log-detail-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 1rem;
  }

  .log-detail {
    width: calc(50% - 8px);
  }

  .label {
    color: var(--textDark);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.125px;
  }

  .vendor-event {
    color: var(--textDark);
  }

  .lender-event {
    color: var(--textDark);
  }

  .vendor-icon {
    background-color: var(--primaryLight);
    color: var(--primaryDefault);
  }

  .lender-icon {
    background-color: var(--positiveLight);
    color: var(--positiveDark);
  }

  .log-body .body {
    color: var(--textDefault);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
</style>
