<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { isEmpty, camelCase, startCase } from 'lodash-es';
  import { CheckboxCard, Dropdown, TextInput, Skeleton } from '@xpanseinc/ui-components';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';
  import Divider from '../Divider.svelte';
  import { form, validationResults, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import { currentUserCreatePermissions } from '../../../stores/users';
  import { validate } from '../../../schemas/validate';
  import { orderSchema } from '../../../schemas/place-order';
  import type { Order } from '../../../schemas/place-order';
  import { reportApi } from '../../../stores/api';

  const dirtyMap: { [k in keyof Order]?: boolean } = {};
  let clients = [];

  type ProductCard = {
    title: string;
    value: PlaceOrderProductTypeEnum;
    description: string;
  };

  let productCards: ProductCard[] = [];

  let selectedProductsMap: { [k in PlaceOrderProductTypeEnum]?: boolean } = {};

  onMount(async () => {
    const clientsResult = await $reportApi.getClients();
    clients = clientsResult.map((x) => ({ label: x.name, value: x.id }));

    if (!$form.order.products.length && productCards.length === 1) {
      productCards.forEach((product) => {
        toggleProductSelection(product.value);
      });
    }
  });

  function toggleProductSelection(product: PlaceOrderProductTypeEnum): void {
    dirtyMap.products = true;
    selectedProductsMap[product] = !selectedProductsMap[product];
    $form.order.products = Object.keys(selectedProductsMap).filter(
      (product) => selectedProductsMap[product],
    ) as PlaceOrderProductTypeEnum[];
  }

  $: $validationResults.orderDetails = validate(orderSchema, $form.order);

  $: if (isEmpty(selectedProductsMap)) {
    $form.order.products.forEach((product) => {
      selectedProductsMap[product] = true;
    });
  }

  $: if ($currentUserCreatePermissions) {
    productCards = Object.keys(PlaceOrderProductTypeEnum)
      .filter((product) => {
        if (
          $currentUserCreatePermissions
            .map((per) => per.name)
            .includes(PlaceOrderProductTypeEnum[product])
        ) {
          return product;
        }
      })
      .map((product) => {
        return {
          title: `${startCase(camelCase(PlaceOrderProductTypeEnum[product]))} Order`,
          value: PlaceOrderProductTypeEnum[product],
          description: `Select to place a ${startCase(
            camelCase(PlaceOrderProductTypeEnum[product]),
          )} order`,
        };
      });
  }
</script>

<FormRow>
  <FormBlock title="Order Details" width="520px" showRequiredTag>
    <div class="input-container">
      <Dropdown
        invalid="{dirtyMap.lender && $validationResults.orderDetails.errors.lender}"
        label="Lender / Department Name *"
        name="lender"
        placeholder="Select One"
        options="{clients}"
        value="{$form.order.lender}"
        on:blur="{() => {
          dirtyMap.lender = true;
        }}"
        on:select="{(e) => {
          $form.order.lender = e.detail.value;
          $form.order.lenderName = e.detail.label;
        }}"
      />
    </div>
  </FormBlock>
</FormRow>
<Divider />
<FormRow>
  <div class="order-selection">
    <h3 class="header-m header-m--bold textDark">Order Selection</h3>
    <p class="header-s">You must select at least one order from the list below.</p>
    <div class="products">
      {#each productCards as { title, value, description }}
        {#if productCards.length}
          <div in:fade>
            <CheckboxCard
              name="{title}"
              checked="{$form.order.products.includes(value)}"
              on:change="{() => {
                // FMC REQUIREMENT - Title and Closing selections are tethered
                const availableCards = productCards.map((product) => product.value);
                if (
                  // If both TITLE and CLOSING are available options
                  (availableCards.includes(PlaceOrderProductTypeEnum.Title) &&
                    availableCards.includes(PlaceOrderProductTypeEnum.Closing) &&
                    // AND the selected card is TITLE or CLOSING
                    value === PlaceOrderProductTypeEnum.Title) ||
                  value === PlaceOrderProductTypeEnum.Closing
                ) {
                  toggleProductSelection(PlaceOrderProductTypeEnum.Title);
                  toggleProductSelection(PlaceOrderProductTypeEnum.Closing);
                } else {
                  toggleProductSelection(value);
                }
              }}"
            >
              <div class="product">
                <h3 class="header-l">{title}</h3>
                <div class="body-small">{description}</div>
              </div>
            </CheckboxCard>
          </div>
        {:else if !$currentUserCreatePermissions}
          <Skeleton height="80px" />
        {/if}
      {/each}
    </div>
  </div>
</FormRow>

<style>
  .input-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    max-width: 300px;
  }

  .input-container :global(.dropdown) {
    width: 600px !important;
  }

  .order-selection {
    margin: 0 0 8px;
  }

  .header-s {
    margin: 0 0 1rem;
    font-weight: 500;
  }

  .header-m {
    margin-bottom: 0.25rem;
  }

  .products {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    gap: 8px;
  }

  .products :global(.checkbox-card) {
    width: 300px;
  }

  .products :global(.checkbox-card .header-l) {
    line-height: 1.5;
  }

  .products :global(.checkbox-card .body-small) {
    color: var(--textDefault);
  }
</style>
