<script lang="ts">
  import { get } from 'lodash-es';
  import { productDetailsConfig } from '../../constants/place-order';
  import { imask, masks } from '../../constants/inputMasking';
  import FormBlock from './FormBlock.svelte';
  import FormRow from './FormRow.svelte';
  import { getComponent, getProps } from './renderFromConfig';
  import { IconButton } from '@xpanseinc/ui-components';
  import { PartyContactTypeEnum, PartyPartyRoleEnum } from '@xpanseinc/ui-backend-api';
  import { validationResults } from '../../stores/placeOrder';
  import { form, PlaceOrderProductTypeEnum, ApplicationTypeEnum } from '../../stores/placeOrder';
  import { getTaxProducts } from '../../constants/place-order/incomeTax';
  import { userOrgType } from '../../stores/profile';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import Divider from './Divider.svelte';
  import IncomeProductForm from './IncomeProductForm.svelte';
  import { setContext } from 'svelte';
  import AddApplicant from './dialogs/AddApplicant.svelte';
  import ChangeAppTypeDialog from './dialogs/ChangeAppTypeDialog.svelte';
  import ErrorMessage from './ErrorMessage.svelte';
  import { AttachmentTypeEnum, DocumentTypeEnum } from '../../schemas/place-order/income';

  const { verificationOfTax } = productDetailsConfig;

  export let appIdx;
  export let application;
  let visible = false;
  let appType: ApplicationTypeEnum;

  let applicantError;
  let coApplicantError;

  $: if ($validationResults.productDetails[PlaceOrderProductTypeEnum.Income]?.errors) {
    const errors = $validationResults.productDetails[PlaceOrderProductTypeEnum.Income].errors;

    applicantError = get(errors, `applications[${appIdx}].applicant`)
      ?.map((er) => er.message)
      .join(', ');

    coApplicantError = get(errors, `applications[${appIdx}].coApplicant`)
      ?.map((er) => er.message)
      .join(', ');
  }

  // temp data until UI backend finishes income service
  let attachmentOptions = [
    { label: AttachmentTypeEnum.E_SIGN, value: AttachmentTypeEnum.E_SIGN },
    { label: AttachmentTypeEnum.WET_SIGNED, value: AttachmentTypeEnum.WET_SIGNED },
  ];

  $: appType = application.applicationType;

  const getFullName = (item) => `${item.firstName} ${item.middleName} ${item.lastName}`;
  const getContactHeader = (item) => {
    let header;
    if (item.contactType === PartyContactTypeEnum.Individual) {
      header = getFullName(item);
    } else {
      header = `${item.companyName}`;
    }
    return header;
  };
  setContext('applicant', {
    component: AddApplicant,
    getHeader: getContactHeader,
    getSSNText: (item) => {
      return item.ssn.substring(item.ssn.length - 4);
    },
  });
  let appTypeHolder: ApplicationTypeEnum;

  function handleAppTypeChange(e) {
    if (!appType) {
      $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].applicationType =
        e.detail.label;
    } else {
      visible = true;
      appTypeHolder = e.detail.label;
    }
  }

  function resetApplication() {
    $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx] = {
      uid: $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].uid,
      applicationType: appTypeHolder,
      attachmentType: '',
      applicant: [],
      coApplicant: [],
      products: getTaxProducts(),
      attachments: [
        {
          fileName: '',
          mimeTypeId: '',
          usageCode: '',
          usageCodeDescription: '',
        },
      ],
    };
  }

  function handleAttachmentType(e) {
    $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].attachmentType =
      e.detail.value;

    if (e.detail.value === AttachmentTypeEnum.WET_SIGNED) {
      $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].attachments = [
        {
          fileName: '',
          mimeTypeId: '',
          usageCode: DocumentTypeEnum.TYPE_4506_C,
          usageCodeDescription: DocumentTypeEnum.TYPE_4506_C,
        },
      ];
    }

    if (e.detail.value === AttachmentTypeEnum.E_SIGN) {
      $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].attachments = [
        {
          fileName: '',
          mimeTypeId: '',
          usageCode: DocumentTypeEnum.TYPE_AUDIT_LOG,
          usageCodeDescription: DocumentTypeEnum.TYPE_AUDIT_LOG,
        },
        {
          fileName: '',
          mimeTypeId: '',
          usageCode: DocumentTypeEnum.TYPE_4506_C,
          usageCodeDescription: DocumentTypeEnum.TYPE_4506_C,
        },
      ];
    }
  }
</script>

<div class="container">
  {#if appIdx > 0}<div class="trash">
      <IconButton
        ariaLabel="delete application"
        icon="{Trash2Icon}"
        name="delete-row"
        on:click="{() => {
          $form.productDetails[PlaceOrderProductTypeEnum.Income].applications =
            $form.productDetails[PlaceOrderProductTypeEnum.Income].applications.filter(
              (app) => app.uid !== application.uid,
            );
        }}"
      />
    </div>{/if}
  <FormRow>
    <FormBlock title="Application" width="100%">
      <div class="row">
        <div>
          <svelte:component
            this="{getComponent(verificationOfTax.fields.applicationType)}"
            {...getProps(verificationOfTax.fields.applicationType)}
            on:select="{(e) => {
              handleAppTypeChange(e);
            }}"
          />
        </div>
        <div class="form-item">
          <svelte:component
            this="{getComponent(verificationOfTax.fields.attachmentType)}"
            {...getProps(verificationOfTax.fields.attachmentType)}
            options="{attachmentOptions}"
            on:select="{(e) => {
              handleAttachmentType(e);
            }}"
          />
        </div>
      </div>
      {#if !appType}
        <p class="header-m">Please select an application type.</p>
      {/if}
    </FormBlock>
  </FormRow>
  {#if appType === ApplicationTypeEnum.Business}
    <FormRow>
      <FormBlock width="100%">
        <div class="row">
          <div class="form-item two-col">
            <svelte:component
              this="{getComponent(verificationOfTax.fields.businessName)}"
              {...getProps(verificationOfTax.fields.businessName)}
              on:change="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                  appIdx
                ].businessName = e.target.value;
              }}"
            />
          </div>
          <div class="form-item">
            <svelte:component
              this="{getComponent(verificationOfTax.fields.signersTitle)}"
              {...getProps(verificationOfTax.fields.signersTitle)}
              on:change="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                  appIdx
                ].signersTitle = e.target.value;
              }}"
            />
          </div>
          <div class="form-item">
            <svelte:component
              this="{getComponent(verificationOfTax.fields.ein)}"
              {...getProps(verificationOfTax.fields.ein)}
              maskPackage="{imask}"
              maskOptions="{masks.ein}"
              on:accept="{({ detail: { masked } }) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].ein =
                  masked.unmaskedValue;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>
  {/if}
  {#if appType}
    <FormRow>
      <FormBlock width="100%">
        <svelte:component
          this="{getComponent(verificationOfTax.fields.applicantInformation)}"
          badgeLabel
          {...getProps(verificationOfTax.fields.applicantInformation)}
          itemList="{application.applicant}"
          contextKey="applicant"
          placeholderButtonLabel="{verificationOfTax.fields.applicantInformation.label}*"
          maxItems="1"
          orgType="{$userOrgType}"
          on:itemChange="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].applicant =
              e.detail;
          }}"
        />
        {#if applicantError}
          <ErrorMessage message="{applicantError}" />
        {/if}
      </FormBlock>
    </FormRow>

    {#if appType === ApplicationTypeEnum.Joint}
      <FormRow>
        <FormBlock width="100%">
          <svelte:component
            this="{getComponent(verificationOfTax.fields.coapplicantInformation)}"
            badgeLabel="COBORROWER"
            {...getProps(verificationOfTax.fields.coapplicantInformation)}
            itemList="{application.coApplicant}"
            contextKey="applicant"
            placeholderButtonLabel="{verificationOfTax.fields.coapplicantInformation.label}*"
            maxItems="1"
            on:itemChange="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                appIdx
              ].coApplicant = e.detail.map((applicant) => ({
                ...applicant,
                partyRole: PartyPartyRoleEnum.Coborrower,
              }));
            }}"
          />
          {#if coApplicantError}
            <ErrorMessage message="{coApplicantError}" />
          {/if}
        </FormBlock>
      </FormRow>
    {/if}

    <IncomeProductForm application="{application}" appIdx="{appIdx}" />

    <Divider />
  {/if}
  <ChangeAppTypeDialog bind:visible on:confirm="{resetApplication}" />
</div>

<style>
  .container {
    width: 100%;
  }

  .trash {
    float: right;
  }
</style>
