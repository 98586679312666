<script lang="ts">
  import { UploadInput } from '@xpanseinc/ui-components';
  import AddressForm from '../AddressForm.svelte';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';
  import { getComponent, getProps } from '../renderFromConfig';
  import { InputTypeEnum } from '../../../constants/place-order';
  import type { PaymentAsProduct } from '../../../schemas/place-order';
  import { validate } from '../../../schemas/validate';
  import { form, validationResults, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import { userOrgType } from '../../../stores/profile';
  import { getPaymentFormBlocks } from '../../../constants/place-order/payment/form-configuration';
  import { getSchema } from '../../../constants/place-order/payment/schema';
  import { bulkPaymentImportTemplate } from '../../../constants/csvTemplates';
  import { exportCSVFile } from '../../../utils/jsonToCsv';
  import { AlertCircleIcon } from 'svelte-feather-icons';

  const productType = PlaceOrderProductTypeEnum.Payment;
  const schema = getSchema($userOrgType, $form.productDetails[productType].paymentPurpose);
  const dirtyMap: { [k in keyof PaymentAsProduct]?: boolean } = {};

  let uploadError = false;

  const parsePayeesFromCsv = async (file) => {
    uploadError = false;

    const text = await file.text();
    const rows = text.split(/\r?\n/);

    const payees = rows.slice(1).reduce((acc, row) => {
      if (row.length > 0) {
        const [
          firstName,
          lastName,
          email,
          amount,
          licenseNumber,
          line1,
          line2,
          city,
          state,
          zip,
          county,
        ] = row.split(',');

        acc.push({
          firstName,
          lastName,
          email,
          amount,
          licenseNumber,
          address: {
            line1,
            line2,
            city,
            state,
            zip,
            county,
          },
        });
      }

      return acc;
    }, []);

    if (payees.length === 0) {
      uploadError = true;
    }

    $form.productDetails[productType].payees = payees;
  };

  let formBlocks;

  const downloadTemplate = (): void => {
    const { headers, items } = bulkPaymentImportTemplate;
    exportCSVFile(headers, items, 'bulk_payment_import_template');
  };

  $: {
    formBlocks = getPaymentFormBlocks(
      $userOrgType,
      $form.productDetails[productType].paymentPurpose,
    );
    $validationResults.productDetails[productType] = validate(
      getSchema($userOrgType, $form.productDetails[productType].paymentPurpose),
      $form.productDetails[productType],
    );
  }
</script>

{#each formBlocks as formBlock}
  <FormRow>
    <FormBlock title="{formBlock.title}" width="100%">
      {#each formBlock.rows as row}
        <div class="row">
          {#each row.fields as field}
            <div style="width: {field.width};">
              <svelte:component
                this="{getComponent(field)}"
                {...getProps(field)}
                disabled="{field.isDisabled}"
                invalid="{dirtyMap[field.name] &&
                  $validationResults.productDetails[productType].errors[field.name]}"
                value="{$form.productDetails[productType][field.name]}"
                on:select="{field.type === InputTypeEnum.Dropdown
                  ? (e) => {
                      $form.productDetails[productType][field.name] = e.detail.value;
                    }
                  : null}"
                on:blur="{field.type !== InputTypeEnum.Dropdown
                  ? () => (dirtyMap[field.name] = true)
                  : null}"
                on:change="{field.type !== InputTypeEnum.Dropdown
                  ? (e) => {
                      $form.productDetails[productType][field.name] = e.target.value;
                    }
                  : null}"
              />
            </div>
          {/each}
        </div>
      {/each}
    </FormBlock>
  </FormRow>
{/each}

{#if $form.productDetails[productType].paymentPurpose !== 'VA Broker Payment'}
  <AddressForm dirtyMap="{dirtyMap}" productType="{productType}" />
{:else}
  <FormBlock title="Bulk VA Broker Payment Import" width="100%">
    <div class="row">
      <div style="width: 292px;">
        <UploadInput
          label="Attach Payments File"
          accept="text/csv"
          acceptMsg="CSV"
          dataTestId="bulk-payment-file-select"
          on:change="{(evt) => {
            const file = evt.target.files[0];
            if (file) {
              parsePayeesFromCsv(file);
            }
          }}"
          on:remove="{() => {
            uploadError = false;
            $form.productDetails[productType].payees = [];
          }}"
        />
      </div>
    </div>
  </FormBlock>

  {#if uploadError}
    <div class="import-error">
      <div class="import-result">
        <AlertCircleIcon />
        <div>
          <p>
            The file you uploaded doesn't match our sample template. All fields must be included for
            each payee. Please adjust your file and try again.
          </p>
        </div>
      </div>

      <button class="download-template-btn" on:click="{downloadTemplate}">
        Use our sample template to get started.
      </button>
    </div>
  {/if}
{/if}

<style>
  .import-error :global(.feather) {
    color: var(--negativeDefault);
    height: 45px;
    width: 45px;
  }

  .import-result {
    align-items: center;
    display: flex;
    gap: 24px;
    margin: 16px 0;
  }

  .download-template-btn {
    text-decoration: none;
    color: var(--actionPrimaryDefault);
    border: 0 none;
    background-color: transparent;
  }
</style>
