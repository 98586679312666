<script lang="ts">
  import { datadogLogs } from '@datadog/browser-logs';
  import { PermissionTypeEnum } from '@xpanseinc/authorization-service-api-rest';
  import { LoadingModal, Toast } from '@xpanseinc/ui-components';
  import { OrganizationOrgTypeEnum } from '@xpanseinc/ui-backend-api';
  import { setContext } from 'svelte';
  import {
    CalendarIcon,
    DollarSignIcon,
    GridIcon,
    HelpCircleIcon,
    HomeIcon,
    LayersIcon,
    SlidersIcon,
    UsersIcon,
  } from 'svelte-feather-icons';
  import { ToastContainer } from 'svelte-toasts';
  import Router, { location as locationStore } from 'svelte-spa-router';
  import { SidebarNav } from './components/navigation';
  import { XPANSE_API_DOMAIN } from './const';
  import urls from './urls';
  import { userProfileApi, userApi } from './stores/api';
  import { auth, sessionTimer } from './stores/auth';
  import { profile } from './stores/profile';
  import 'flatpickr/dist/flatpickr.css';
  import {
    currentUser,
    currentUserPermissions,
    currentUserCreatePermissions,
    getAdminStatus,
    organizationId,
  } from './stores/users';
  import Orders from './views/Orders.svelte';
  import SlaScorecards from './views/SlaScorecards.svelte';
  import OrderDetails from './views/OrderDetails.svelte';
  import BilledEvents from './views/BilledEvents.svelte';
  import AdvancedSearch from './views/AdvancedSearch.svelte';
  import Home from './views/Home.svelte';
  import NotFound from './views/NotFound.svelte';
  import PlaceOrderOrderDetails from './views/PlaceOrder/PlaceOrderOrderDetails.svelte';
  import PlaceOrderLoanDetails from './views/PlaceOrder/PlaceOrderLoanDetails.svelte';
  import PlaceOrderProductDetails from './views/PlaceOrder/PlaceOrderProductDetails.svelte';
  import SendEvent from './views/SendEvent.svelte';
  import UserManagement from './views/UserManagement.svelte';
  import MilestoneDashboard from './views/MilestoneDashboard.svelte';
  import PermissionDenied from './views/PermissionDenied.svelte';
  import DeliveryErrors from './views/DeliveryErrors.svelte';
  import { searchFilter } from './stores/orders';
  import { sub } from 'date-fns';
  import AddUser from './views/AddUser.svelte';
  import { popToast } from './utils/toasts';

  let badUser = false;
  let initializing = true;
  let isLender;
  let isProvider;
  let isBroker;
  let isOrderAdmin;
  let isUserAdmin;
  let isInvoiceAdmin;
  let isProductCreator;

  $: if ($currentUserCreatePermissions) {
    isProductCreator = !!$currentUserCreatePermissions && $currentUserCreatePermissions.length;
  }

  $: if ($currentUserPermissions) {
    isOrderAdmin = getAdminStatus(PermissionTypeEnum.OrderAdmin);
    isUserAdmin = getAdminStatus(PermissionTypeEnum.UserAdmin);
    isInvoiceAdmin = getAdminStatus(PermissionTypeEnum.Invoicing);
  }

  $searchFilter = {
    ...$searchFilter,
    status: ['In Progress', 'New'],
    from: sub(new Date(), { months: 4 }),
  };

  userProfileApi.subscribe(async (api) => {
    if (api) {
      $profile = await api.getUserProfile();

      isLender = $profile.hasLenderAccess;
      isProvider = $profile.hasProviderAccess;
      isBroker = $profile.organization?.orgType === OrganizationOrgTypeEnum.Broker;

      initializing = false;
    }
  });

  userApi.subscribe(async (api) => {
    if (api) {
      try {
        $currentUser = await api.getCurrentUser();
        organizationId.set($currentUser.organizationId);
      } catch (error) {
        popToast({
          title: `User not recognized: "current-user status: ${error.status}"`,
          type: 'error',
        });
        initializing = false;
        badUser = true;
      }
    }
  });

  $: hasClosingPermission = $currentUserPermissions.product.CLOSING !== null;

  $: routes = {
    [urls.home]: Home,
    [urls.milestoneDashboard]: hasClosingPermission ? MilestoneDashboard : PermissionDenied,
    // [urls.deliveryErrors]: $currentUser?.isOrderAdmin ? DeliveryErrors : PermissionDenied,
    [urls.billedEvents]: isProvider && isInvoiceAdmin ? BilledEvents : PermissionDenied,
    [urls.orders]: Orders,
    [urls.advancedSearch]: AdvancedSearch,
    [urls.orderDetails]: OrderDetails,
    [urls.sendEvent]: SendEvent,
    [urls.placeOrder]:
      (isProductCreator && isLender) || isBroker ? PlaceOrderOrderDetails : PermissionDenied,
    [urls.placeOrderLoanDetails]:
      (isProductCreator && isLender) || isBroker ? PlaceOrderLoanDetails : PermissionDenied,
    [urls.placeOrderProductDetails]:
      (isProductCreator && isLender) || isBroker ? PlaceOrderProductDetails : PermissionDenied,
    [urls.slaScorecards]: SlaScorecards,
    [urls.userManagement]: isUserAdmin ? UserManagement : PermissionDenied,
    [urls.addUser]: isUserAdmin ? AddUser : PermissionDenied,
    [urls.editUser]: isUserAdmin ? AddUser : PermissionDenied,
    [urls.copyUser]: isUserAdmin ? AddUser : PermissionDenied,
    // The catch-all route must always be last
    [urls.notFound]: NotFound,
  };

  function getNavItems(location, permissions, isLender, isProvider) {
    const items = [
      {
        label: 'Home',
        icon: HomeIcon,
        href: `/#${urls.home}`,
        active: location === urls.home,
      },
      {
        label: 'Fulfillment',
        icon: LayersIcon,
        href: `/#${urls.orders}`,
        active:
          location.indexOf(urls.orders) > -1 ||
          location.indexOf(urls.orderDetail) > -1 ||
          location.indexOf(urls.placeOrder) > -1,
      },
      {
        label: 'SLA Scorecard',
        icon: GridIcon,
        href: `/#${urls.slaScorecards}`,
        active: location.indexOf(urls.slaScorecards) > -1,
      },
      {
        label: 'Allocation',
        icon: SlidersIcon,
        href: urls.allocation,
        isExternal: true,
      },
      {
        label: 'Support',
        icon: HelpCircleIcon,
        href: urls.support,
        isExternal: true,
      },
    ];
    if (hasClosingPermission) {
      items.push({
        label: 'Milestone Dashboard',
        icon: CalendarIcon,
        href: `/#${urls.milestoneDashboard}`,
        active: location.indexOf(urls.milestoneDashboard) > -1,
      });
    }
    if (isProvider && permissions?.admin?.INVOICING) {
      items.push({
        label: 'Billing & Invoices',
        icon: DollarSignIcon,
        href: `/#${urls.billedEvents}`,
        active: location.indexOf(urls.billedEvents) > -1,
      });
    }
    if (permissions?.admin?.USERADMIN) {
      items.push({
        label: 'User Management',
        icon: UsersIcon,
        href: `/#${urls.userManagement}`,
        active: location.indexOf(urls.userManagement) > -1,
      });
    }
    // if (user?.isOrderAdmin) {
    //   items.push({
    //     label: 'Xpanse Delivery Errors',
    //     icon: AlertTriangleIcon,
    //     href: `/#${urls.deliveryErrors}`,
    //     active: location.indexOf(urls.deliveryErrors) > -1,
    //   });
    // }
    return items;
  }

  datadogLogs.init({
    clientToken: 'pub0c8a44c63592634ecaf833651932dc5a',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    useSecureSessionCookie: true,
  });

  sessionTimer.subscribe(() => {});

  // Check if Auth0 is being initalized, then redirect to login if needed
  $: {
    if (!$auth.isLoading && !$auth.isAuthenticated) {
      $auth.login();
    } else {
      setContext('auth', $auth);
    }
  }
</script>

<div class="app">
  {#if initializing}
    <LoadingModal text="Loading" isFullScreen="true" />
  {:else}
    {#if $currentUser}
      <SidebarNav
        items="{getNavItems($locationStore, $currentUserPermissions, isLender, isProvider)}"
      />
    {/if}
    {#if $auth.isLoading || !$auth.isAuthenticated}
      <LoadingModal text="Loading" isFullScreen="true" />
    {:else if $currentUser}
      <Router bind:routes />
    {:else if badUser}
      <PermissionDenied />
    {/if}
  {/if}

  <ToastContainer width="800px" let:data>
    <Toast data="{data}" />
  </ToastContainer>
</div>

<style>
  .app {
    display: flex;
    height: 100vh;
  }

  .app :global(.toast-container.bottom-left) {
    bottom: 4px !important;
    left: 72px !important;
  }
</style>
