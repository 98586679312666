export function getYearForm() {
  let year = new Date().getFullYear() - 1;
  const years = {};

  for (let i = 0; i < 4; i += 1) {
    years[year] = false;
    year -= 1;
  }

  return years;
}

export const getTaxProducts = () => ({
  Individual: [
    { name: '1040', productId: '1040', transcriptType: '', years: getYearForm() },
    { name: 'Verify Tax Filing', productId: 'TFV', transcriptType: '', years: getYearForm() },
    { name: 'Wages and Income', productId: 'W2', transcriptType: '', years: getYearForm() },
  ],
  Joint: [
    { name: '1040', productId: '1040', transcriptType: '', years: getYearForm() },
    { name: 'Verify Tax Filing', productId: 'TFV', transcriptType: '', years: getYearForm() },
    { name: 'Wages and Income', productId: 'W2', transcriptType: '', years: getYearForm() },
  ],
  Business: [
    { name: '1065', productId: '1065', transcriptType: '', years: getYearForm() },
    { name: '1120', productId: 'F1120', transcriptType: '', years: getYearForm() },
    { name: '1120S', productId: 'F1120S', transcriptType: '', years: getYearForm() },
  ],
});
