<script>
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { camelCase, isDate, startCase } from 'lodash-es';
  import { formatDate } from '../utils/formatDate';
  import { MailIcon, PhoneIcon } from 'svelte-feather-icons';
  import { getLabel } from '../constants/sendEventDataLabels';
  import { flattenObject } from '../utils/flattenObject';

  export let details = {};
  export let title = '';
  export let visible;

  const flattenedDetails = flattenObject(details);

  function close() {
    visible = false;
  }
</script>

<Dialog title="{title}" on:close="{close}" bind:visible>
  <div slot="body">
    {#each Object.entries(flattenedDetails) as [key, value], i}
      {#if value !== undefined && value !== null && value !== ''}
        <!-- {key} -->
        {#if key === 'firstName' || key === 'lastName' || key === 'fullName'}
          <h5 class="label">{getLabel(key)}</h5>
          <h4>{value}</h4>
        {:else if key === 'email'}
          <div class="contact-method">
            <MailIcon size="15" />
            <p class="body-small">
              <a class="link" href="mailto:{value}">{value}</a>
            </p>
          </div>
        {:else if key === 'phone'}
          <div class="contact-method">
            <PhoneIcon size="15" />
            <p class="body-small">
              <a class="link" href="tel:{value}">{value}</a>
            </p>
          </div>
        {:else}
          <h5 class="label">{getLabel(key)}</h5>
          {#if isDate(value)}
            <p class="body" title="{value}">
              {formatDate(value, 'ET')}
            </p>
          {:else if key === 'recordingEndorsementType' || key === 'deliveryMethodType' || key === 'partyRole'}
            <p class="body">{startCase(camelCase(value))}</p>
          {:else}
            <p class="body">{value}</p>
          {/if}
        {/if}
      {/if}
    {/each}
  </div>
  <footer slot="footer">
    <Button color="primary" name="close-recording-dialog" label="Close" on:click="{close}" />
  </footer>
</Dialog>

<style>
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .body {
    margin-bottom: 16px;
  }

  .contact-method {
    display: flex;
  }

  .contact-method :global(svg) {
    margin-right: 8px;
  }

  .label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textDefault);
  }
</style>
