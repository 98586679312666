<script>
  import { createEventDispatcher } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { Chip } from '@xpanseinc/ui-components';
  import { vendors, fetchVendors } from '../../stores/reports';
  import { searchFilterLabel } from '../../constants/searchFilterChipLabels';
  import {
    activeFilter,
    getDefaultSearchFilter,
    searchFilter,
    stagedFilter,
  } from '../../stores/orders';
  import urls from '../../urls';

  const dispatch = createEventDispatcher();

  export let displayedChips = {};
  export let getOrders;

  const defaultFilter = getDefaultSearchFilter();
  const vendorMap = {};

  $: {
    for (let i = 0; i < $vendors?.length; i += 1) {
      vendorMap[$vendors[i]?.value] = $vendors[i].label;
    }
  }

  fetchVendors();

  const getChipsToDisplay = () => {
    Object.keys($searchFilter).forEach((field) => {
      const value = $searchFilter[field];
      displayedChips[field] = false;
      if (value) {
        if (
          (typeof value === 'string' || typeof value === 'boolean') &&
          defaultFilter[field] !== value &&
          field !== 'eventReceivedStatus' &&
          field !== 'eventDate'
        ) {
          displayedChips[field] = true;
        } else if (Array.isArray(value) && value.length > 0) {
          displayedChips[field] = true;
        } else if (value instanceof Date && field !== 'to') {
          if (value.getTime() !== defaultFilter[field].getTime()) {
            displayedChips[field] = true;
          }
        }
      }
    });

    // When the system default for all time (currently last 50 years) is the [from, to] range, remove the date range chips
    if ($searchFilter.to.getFullYear() - $searchFilter.from.getFullYear() === 50) {
      displayedChips.from = false;
      displayedChips.to = false;
    }
  };

  function clearFilter(field) {
    $searchFilter[field] = defaultFilter[field];
    $stagedFilter[field] = defaultFilter[field];
    if (field === 'from') {
      $searchFilter.to = defaultFilter.to;
      $stagedFilter.to = defaultFilter.to;
      $searchFilter.eventDate = defaultFilter.eventDate;
      $stagedFilter.eventDate = defaultFilter.eventDate;
    }
    getOrders();
    dispatch('clearedField', field);
  }

  $: getChipsToDisplay($searchFilter);

  let searchFilterLabelMap;
  $: searchFilterLabelMap = searchFilterLabel($searchFilter, vendorMap);
  $: if ($activeFilter) {
    $stagedFilter = { ...$searchFilter };
  }
</script>

<div class="chip-container">
  {#each Object.keys($searchFilter) as key}
    {#if displayedChips[key]}
      <Chip
        on:click="{() => {
          clearFilter(key);
        }}"
      >
        {searchFilterLabelMap[key].substring(
          0,
          searchFilterLabelMap[key].indexOf(':'),
        )}{searchFilterLabelMap[key].substring(searchFilterLabelMap[key].indexOf(':'))}
      </Chip>
    {/if}
  {/each}
  {#if Object.values(displayedChips).indexOf(true) > -1}
    <button
      class="btn basic pill"
      on:click="{() => {
        $searchFilter = getDefaultSearchFilter();
        $stagedFilter = getDefaultSearchFilter();
        $activeFilter.id = null;
        $activeFilter.name = null;
        getOrders();
        push(urls.orders);
        dispatch('clearedField', 'all');
      }}"
    >
      Clear all
    </button>
  {/if}
</div>

<style>
  .chip-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin: 8px 0 8px 0;
  }
</style>
