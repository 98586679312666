<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import uniqueId from 'lodash-es/uniqueId';
  import { startCase, camelCase } from 'lodash-es';
  import FormRow from '../FormRow.svelte';
  import FormBlock from '../FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import { getPaymentSchema } from '../../../schemas/place-order';
  import { getComponent, getProps } from '../renderFromConfig';
  import { form, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import {
    PayerType,
    PaymentCategoryType,
    PaymentType,
    getPaymentInfoConfig,
    OrgTypeEnum,
  } from '../../../constants/place-order/index';
  import type { PayerInformation, Payment } from '../../../schemas/place-order';
  import type { ValidationResult } from '../../../schemas/validate';
  import { sum } from 'lodash';
  import { userOrgType } from '../../../stores/profile';

  export let visible = false;
  export let existingItem = null;

  const { payerInformation, requestedPayments } = getPaymentInfoConfig($userOrgType);

  const dispatch = createEventDispatcher();

  let dirtyMap: { [k in keyof PayerInformation]?: boolean } = {};
  let stagedPayment: PayerInformation = getDefaultForm();
  let payerOptions = [];
  let newPaymentEmail = null;

  function getDefaultForm(): PayerInformation {
    return {
      type: PaymentType.BillTo,
      payer: null,
      firstName: '',
      lastName: '',
      lender: '',
      email: '',
      borrowerInfo: null,
      requestedPayments: [getNewPayment()],
    };
  }

  function initializePayments() {
    if ($userOrgType === OrgTypeEnum.WHOLESALE) {
      onPayerChange(PayerType.Lender);
      if (existingItem) {
        stagedPayment = { ...existingItem };
      }
      populateFees();
    } else if ($userOrgType === OrgTypeEnum.BROKER) {
      if (existingItem) {
        stagedPayment = { ...existingItem };
      }
      stagedPayment.type = PaymentType.Prepaid;
      populateFees();
    } else {
      onPayerChange(PayerType.Lender);
      if (existingItem) {
        stagedPayment = { ...existingItem };
      }
      populateFees();
    }
  }

  function savePayment() {
    if (stagedPayment.payer !== PayerType.Lender) {
      stagedPayment.borrowerInfo.paymentEmail = newPaymentEmail;
    }
    if (!validationResult.valid) {
      Object.keys(validationResult.errors)
        .filter((key) => key.indexOf('contactMethod') === -1)
        .forEach((key) => {
          dirtyMap[key] = true;
        });
      stagedPayment.requestedPayments.forEach((payment) => {
        const key = `payments[${payment.id}]`;
        Object.keys(payment).forEach((field) => {
          dirtyMap[`${key}.${field}`] = true;
        });
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedPayment });
    } else {
      dispatch('save', stagedPayment);
    }
  }

  function getNewPayment(): Payment {
    return {
      id: uniqueId(),
      category: null,
      description: '',
      amount: null,
    };
  }

  function removePayment(i) {
    const [removedPayment] = stagedPayment.requestedPayments.splice(i, 1);
    stagedPayment = stagedPayment;

    // remove items from the dirtyMap for this contact method
    Object.keys(dirtyMap)
      .filter((k: string) => k.indexOf(`paymentMethods[${removedPayment.id}]`) > -1)
      .forEach((k: string) => {
        delete dirtyMap[k];
      });

    if (stagedPayment.requestedPayments.length === 0) {
      stagedPayment.requestedPayments = [getNewPayment()];
    }
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
  }

  function onPayerChange(payer) {
    if (payer === PayerType.Lender) {
      stagedPayment.payer = payer;
      stagedPayment.borrowerInfo = payer;
    } else {
      stagedPayment.payer = payer.partyRole;
      stagedPayment.borrowerInfo = payer;
    }

    if (stagedPayment.payer !== PayerType.Lender) {
      stagedPayment.firstName = stagedPayment.borrowerInfo?.firstName;
      stagedPayment.lastName = stagedPayment.borrowerInfo?.lastName;
      stagedPayment.email =
        stagedPayment.borrowerInfo?.contactMethods.find((x) => x.method === 'Email')?.details || '';
    }

    if (stagedPayment.payer === PayerType.Lender) {
      stagedPayment.type = PaymentType.BillTo;
      stagedPayment.lender = $form.order.lenderName;
      stagedPayment.firstName = '';
      stagedPayment.lastName = '';
      stagedPayment.email = '';
    }

    newPaymentEmail = null;
  }

  function populateFees() {
    if ($form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.appraisalFee) {
      const { appraisalFee, additionalFees } =
        $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees;

      stagedPayment.requestedPayments[0].category = PaymentCategoryType.AppraisalFee;
      stagedPayment.requestedPayments[0].amount = sum([
        appraisalFee.amount,
        ...additionalFees.map((x) => x.amount),
      ]);
    }
  }

  $: if (visible) {
    initializePayments();
  }

  $: if (!visible) {
    dirtyMap = {};
    stagedPayment = getDefaultForm();
    populateFees();
  }

  // If there is a saved payment and new fees fetched from the API, update the saved payment
  $: if (
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].payments[0] &&
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.appraisalFee
  ) {
    const { appraisalFee, additionalFees } =
      $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees;

    $form.productDetails[
      PlaceOrderProductTypeEnum.Appraisal
    ].payments[0].requestedPayments[0].amount = sum([
      appraisalFee.amount,
      ...additionalFees.map((x) => x.amount),
    ]);
  }

  $: payerOptions = $form.loanDetails.newLoan.borrowers.map((borrower) => {
    return {
      label: `${startCase(camelCase(borrower.partyRole))} (${borrower.firstName} ${
        borrower.lastName
      })`,
      value: { ...borrower, paymentEmail: '' },
    };
  });

  $: paymentSchema = getPaymentSchema(stagedPayment.payer);

  let validationResult: ValidationResult;
  $: validationResult = validate(paymentSchema, stagedPayment);
</script>

<Dialog title="Payments" titleTag="Required Fields *" on:close="{close}" size="jumbo" bind:visible>
  <div slot="body">
    <FormRow>
      <FormBlock title="{payerInformation.title}" width="100%">
        <div class="row">
          <svelte:component
            this="{getComponent(payerInformation.fields.type)}"
            {...getProps(payerInformation.fields.type)}
            value="{stagedPayment.type}"
            invalid="{dirtyMap.type && validationResult.errors.type}"
            disabled="{stagedPayment.payer && stagedPayment.payer !== PayerType.Lender}"
            on:blur="{() => {
              dirtyMap.type = true;
            }}"
            on:select="{(e) => {
              stagedPayment.type = e.detail.value;
            }}"
          />
          {#if $userOrgType === OrgTypeEnum.BROKER}
            <div style="width: 280px;">
              <svelte:component
                this="{getComponent(payerInformation.fields.payer)}"
                {...getProps(payerInformation.fields.payer)}
                options="{[...payerOptions]}"
                value="{stagedPayment.borrowerInfo}"
                invalid="{dirtyMap.payer && validationResult.errors.payer}"
                on:blur="{() => {
                  dirtyMap.payer = true;
                }}"
                on:select="{(e) => {
                  onPayerChange(e.detail.value);
                }}"
              />
            </div>
          {:else}
            <div style="width: 280px;">
              <svelte:component
                this="{getComponent(payerInformation.fields.payer)}"
                {...getProps(payerInformation.fields.payer)}
                options="{[...payerOptions, { label: 'Lender', value: PayerType.Lender }]}"
                value="{stagedPayment.borrowerInfo}"
                invalid="{dirtyMap.payer && validationResult.errors.payer}"
                on:blur="{() => {
                  dirtyMap.payer = true;
                }}"
                on:select="{(e) => {
                  onPayerChange(e.detail.value);
                }}"
              />
            </div>
          {/if}

          {#if stagedPayment.payer === PayerType.Lender}
            <svelte:component
              this="{getComponent(payerInformation.fields.lender)}"
              {...getProps(payerInformation.fields.lender)}
              value="{stagedPayment.lender}"
              disabled
              on:blur="{() => {
                dirtyMap.lender = true;
              }}"
              on:change="{(e) => {
                stagedPayment.lender = e.target.value;
              }}"
            />
          {/if}
        </div>

        {#if stagedPayment.payer && stagedPayment.payer !== PayerType.Lender}
          <div class="row">
            <svelte:component
              this="{getComponent(payerInformation.fields.firstName)}"
              {...getProps(payerInformation.fields.firstName)}
              value="{stagedPayment.firstName}"
              invalid="{dirtyMap.firstName && validationResult.errors.firstName}"
              disabled
              on:blur="{() => {
                dirtyMap.firstName = true;
              }}"
              on:change="{(e) => {
                stagedPayment.firstName = e.target.value;
              }}"
            />
            <svelte:component
              this="{getComponent(payerInformation.fields.lastName)}"
              {...getProps(payerInformation.fields.lastName)}
              value="{stagedPayment.lastName}"
              invalid="{dirtyMap.lastName && validationResult.errors.lastName}"
              disabled
              on:blur="{() => {
                dirtyMap.lastName = true;
              }}"
              on:change="{(e) => {
                stagedPayment.lastName = e.target.value;
              }}"
            />
            <svelte:component
              this="{getComponent(payerInformation.fields.email)}"
              {...getProps(payerInformation.fields.email)}
              value="{existingItem && stagedPayment.borrowerInfo.paymentEmail
                ? stagedPayment.borrowerInfo.paymentEmail
                : stagedPayment.email}"
              invalid="{dirtyMap.email && validationResult.errors.email}"
              on:blur="{() => {
                dirtyMap.email = true;
              }}"
              on:change="{(e) => {
                stagedPayment.email = e.target.value;
                newPaymentEmail = e.target.value;
              }}"
            />
          </div>
        {/if}
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock
        title="{requestedPayments.title}"
        subtitle="{'Appraisal Fee amount based on the Provider, Product, and Location of the subject property'}"
      >
        {#each stagedPayment.requestedPayments as { id }, index (id)}
          <div class="row">
            <svelte:component
              this="{getComponent(requestedPayments.fields.category)}"
              {...getProps(requestedPayments.fields.category)}
              value="{stagedPayment.requestedPayments[index].category}"
              invalid="{dirtyMap[`requestedPayments[${id}].category`] &&
                validationResult.errors[`requestedPayments[${index}].category`]}"
              disabled="{index === 0}"
              on:blur="{() => {
                dirtyMap[`requestedPayments[${id}].category`] = true;
              }}"
              on:select="{(e) => {
                stagedPayment.requestedPayments[index].category = e.detail.value;
              }}"
            />

            <svelte:component
              this="{getComponent(requestedPayments.fields.amount)}"
              {...getProps(requestedPayments.fields.amount)}
              type="number"
              value="{stagedPayment.requestedPayments[index].amount}"
              invalid="{dirtyMap[`requestedPayments[${id}].amount`] &&
                validationResult.errors[`requestedPayments[${index}].amount`]}"
              disabled="{index === 0}"
              on:blur="{() => {
                dirtyMap[`requestedPayments[${id}].amount`] = true;
              }}"
              on:change="{({ target }) => {
                stagedPayment.requestedPayments[index].amount = target.value
                  ? parseInt(target.value)
                  : null;
              }}"
            />
            {#if stagedPayment.requestedPayments.length > 1 && index > 0}
              <button
                class="remove-contact-method btn-unstyled"
                on:click="{() => removePayment(index)}"
              >
                <Trash2Icon size="24" />
              </button>
            {/if}
          </div>
        {/each}
      </FormBlock>
    </FormRow>

    <Button
      color="basic"
      label="Add Requested Payments"
      name="addRequestedPayments"
      icon="{PlusIcon}"
      on:click="{() => {
        stagedPayment.requestedPayments = [...stagedPayment.requestedPayments, getNewPayment()];
      }}"
    />
  </div>
  <footer slot="footer" class="footer">
    <Button name="close-recording-dialog" label="Close" on:click="{close}" />
    <Button
      color="primary"
      name="close-recording-dialog"
      label="Save"
      disabled="{!validationResult.valid}"
      on:click="{savePayment}"
    />
  </footer>
</Dialog>

<style>
  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }

  .remove-contact-method {
    align-self: baseline;
    position: relative;
    top: 28px;
  }
</style>
