export enum InputTypeEnum {
  AttachmentsModule = 'AttachmentsModule',
  BorrowerModule = 'BorrowerModule',
  Checkbox = 'Checkbox',
  ComplexCheckbox = 'ComplexCheckbox',
  ContactsModule = 'ContactsModule',
  DatePicker = 'DatePicker',
  Dropdown = 'Dropdown',
  EmployersModule = 'EmployersModule',
  FeesAndQuotesModule = 'FeesAndQuotesModule',
  PaymentsModule = 'PaymentsModule',
  PropertyModule = 'PropertyModule',
  SubjectProperty = 'SubjectProperty',
  TextArea = 'TextArea',
  TextCurrency = 'TextCurrency',
  TextDecimal = 'TextDecimal',
  TextInteger = 'TextInteger',
  TextString = 'TextString',
}

export enum OrgTypeEnum {
  PLATFORM_OWNER = 'PlatformOwner',
  BROKER = 'Broker',
  WHOLESALE = 'Wholesale',
}

export enum OrgNamesEnum {
  FREEDOM_WHOLESALE = 'Freedom Wholesale',
  FREEDOM_SERVICING = 'Freedom Servicing',
}

export type Page = {
  [key: string]: Section;
};

export type Section = {
  title: string;
  fields: {
    [key: string]: Field;
  };
};

export type Field = {
  label: string;
  required: boolean;
  type: string;
  options: Object[];
  dependency: string | null;
};

export enum EventIdEnum {
  PLACE_ORDER = 'PlaceOrder',
  FEE_MODIFICATION_APPROVED = 'FeeModificationApproved',
  FEE_MODIFICATION_NO_APPROVED = 'FeeModificationNotApproved',
  PURCHASE_AGREEMENT = 'PurchaseAgreement',
  PAYMENT_REFUND_REQUESTED = 'UpdateStatus/PaymentRefundRequested',
}
