<script type="ts">
  import { onMount } from 'svelte';
  import { RadioPillButton } from '@xpanseinc/ui-components';
  import LoanDetailsNew from './LoanDetailsNew.svelte';
  import LoanDetailsExisting from './LoanDetailsExisting.svelte';
  import { form, LoanSource, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import { push } from 'svelte-spa-router';
  import urls from '../../../urls';
  import { PODIUM_UI_URL } from '../../../const';

  $: isOnlyPaymentOrder =
    $form.order.products.length === 1 &&
    $form.order.products[0] === PlaceOrderProductTypeEnum.Payment;

  onMount(() => {
    // Reroute to beginning of placeOrder flow if navigated here directly
    if ($form.order.lender === '' || $form.order.products.length === 0) {
      push(urls.placeOrder);
    }
    // If there is a product that does not require loan details, go ahead to the next screen
    if (isOnlyPaymentOrder) {
      push(urls.placeOrderProductDetails);
    }
  });
</script>

<div
  on:click="{(evt) => {
    if (evt.target.value === LoanSource.Bulk) {
      const searchParams = new URLSearchParams({
        product: 'title',
        type: LoanSource.Bulk,
      });
      const redirectUrl = `${PODIUM_UI_URL}/place-order?${searchParams.toString()}`;
      window.open(redirectUrl, '_blank');
      push(urls.home);
    }
  }}"
>
  <RadioPillButton
    options="{[
      { label: 'Existing Loan', value: LoanSource.Existing },
      { label: 'New Loan', value: LoanSource.New },
      { label: 'Bulk', value: LoanSource.Bulk },
    ]}"
    bind:value="{$form.loanDetails.loanSource}"
  />
</div>

<div class="content">
  {#if $form.loanDetails.loanSource === LoanSource.New}
    <LoanDetailsNew />
  {:else if $form.loanDetails.loanSource === LoanSource.Existing}
    <LoanDetailsExisting />
  {/if}
</div>

<style>
  .content {
    padding-top: 24px;
  }
</style>
